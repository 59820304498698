(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/accountservices/player-exclusions/assets/javascripts/data/active-exclusions.js') >= 0) return;  svs.modules.push('/components/accountservices/player-exclusions/assets/javascripts/data/active-exclusions.js');

'use strict';

const logger = svs.core.log.getLogger('accountservices: player-exclusions');
const apiVersion = svs.core.detect.feature('ft-split-sport-vertical') ? '2' : '1';
let activeExclusionsCache;
const reset = partial => {
  activeExclusionsCache = undefined;
  if (!partial && svs.core.userSession.hasRole(svs.core.userSession.roles.PLAYER)) {
    svs.core.userSession.remove('activeExclusions');
  }
};
const fetchAndCache = function () {
  let cb = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => {};
  svs.core.jupiter.call({
    method: 'GET',
    path: "/player/".concat(apiVersion, "/customizedsettings")
  }, data => {
    var _svs$core$userSession, _svs$core$userSession2;
    const activeExclusions = svs.accountservices.player_exclusions.utils.processAPIData(data.customizedSettings.properties);
    activeExclusions.isBlocked = (_svs$core$userSession = svs.core.userSession) === null || _svs$core$userSession === void 0 || (_svs$core$userSession2 = _svs$core$userSession.isBlocked) === null || _svs$core$userSession2 === void 0 ? void 0 : _svs$core$userSession2.call(_svs$core$userSession);
    if (svs.accountservices.player_exclusions.utils.exclusionsAreCacheable(activeExclusions)) {
      activeExclusionsCache = activeExclusions;
      svs.core.userSession.set('activeExclusions', svs.accountservices.player_exclusions.compressor.deflate(activeExclusions));
    } else {
      reset();
    }
    cb(activeExclusions);
  }, err => {
    logger.error('Unable to get users self exclusions, returning no active pause', err);
    cb({
      hasPause: false
    });
  });
};

const get = function () {
  let cb = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : () => {};
  let forceFetch = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const cachedExlusionAPI = svs.core.userSession.get('activeExclusionsCacheAPI');
  const exlusionAPITarget = svs.core.detect.feature('ft-split-sport-vertical') ? '2' : '1';
  if (!forceFetch && cachedExlusionAPI !== exlusionAPITarget) {
    forceFetch = true;
  }
  if (!forceFetch && activeExclusionsCache && svs.accountservices.player_exclusions.utils.exclusionsAreCacheable(activeExclusionsCache)) {
    cb(activeExclusionsCache);
  } else if (forceFetch) {
    fetchAndCache(cb);
  } else {
    svs.core.userSession.get('activeExclusions', (err, data) => {
      if (err) {
        logger.error('Could not retrieve activeExlucions from userSession storage, getting from api instead', err);
        fetchAndCache(cb);
      } else if (data) {
        activeExclusionsCache = svs.accountservices.player_exclusions.compressor.inflate(data);
        if (activeExclusionsCache && svs.accountservices.player_exclusions.utils.exclusionsAreCacheable(activeExclusionsCache)) {
          cb(activeExclusionsCache);
        } else {
          fetchAndCache(cb);
        }
      } else {
        fetchAndCache(cb);
      }
    });
  }
};

const set = (exclusions, successFn) => {
  const saveToSessionStorage = () => svs.core.userSession.set('activeExclusions', svs.accountservices.player_exclusions.compressor.deflate(activeExclusionsCache), successFn);
  activeExclusionsCache = exclusions;
  svs.core.jupiter.call({
    method: 'DELETE',
    path: '/player/1/onlineflag/lcHideVertical'
  }, saveToSessionStorage, err => {
    logger.warn('Unable to unset lcHideVertical flag at /player/1/onlineflag/lcHideVertical', err);
    saveToSessionStorage();
  });
};
svs.accountservices.player_exclusions.activeExlucions = {
  get,
  reset,
  set
};

 })(window);